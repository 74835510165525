import {ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import moment from "moment";


const column: ColumnHelper<IWebsiteLead> = createColumnHelper<IWebsiteLead>()

export const WebsiteLeadsColumn: ColumnDef<IWebsiteLead, string>[] = [
  column.accessor('created_at', {
    header: () => <span>Date</span>,
    cell: info => <span
      className="text-truncate">{moment(info.getValue()).format('LL')}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('type', {
    header: () => <span>Type</span>,
    cell: info => <span className="text-truncate">{info.row.original.type ? `${info.row.original.type}` : '-'}</span>,
    footer: info => info.column.id,
  }),
  column.accessor('email', {
    header: () => <span>Email Address</span>,
    cell: info => <span className="text-truncate">
      {info.getValue()}
    </span>,
    footer: info => info.column.id,
  }),
  column.accessor('phone', {
    header: () => <span>Phone</span>,
    cell: info => <span className="text-truncate">
      {info.row.original.phone ? `${info.row.original.phone}` : '-'}
    </span>,
    footer: info => info.column.id,
  }),
];
